//
// Pager
//

@mixin pager() {
    a.pager {
        width: 30px;
        height: 100%;
        background-color: rgba(0,0,0,0.1);
        color: #fff;
        text-align: center;
        font-size: $font-size-base;
        @include transition();
        z-index: 120;

        @include media-breakpoint-up(lg) {
            width: 50px;
            font-size: $font-size-lg;
        }
        
        &:hover {
            width: 40px;
            background-color: rgba(0,0,0,0.2);
            // background-color: rgba(79,163,184,0.6);
            color: #fff;

            @include media-breakpoint-up(lg) {
                width: 60px;
            }
        }
    }

    .left {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .right {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
