//
// Text Block
//

// Object

.c-text-block {
    padding: 60px 0 0;
    background-color: $verigray-lightest;

    @include media-breakpoint-up(lg) {
        padding: 80px 0 0;
    }


    p {
        margin-bottom: 6em;
        font-family: $font-family-serif;
        font-size: $font-size-base;
        font-weight: 300;

        @include media-breakpoint-up(md) {
            font-size: $font-size-lg + .1;
        }

        @include media-breakpoint-up(xl) {
            font-size: $font-size-lg + .2;
            margin-right: 30%;
        }
    }

    .section-head {
        margin-bottom: 20px;
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $veribrand;

        @include media-breakpoint-up(lg) {
            font-size: $font-size-lg;
        }

        .viewall {font-size: $font-size-base - .1;
            font-family: $font-family-serif;
            font-style: italic;
            text-transform: none;
            letter-spacing: 1px;
        }
    }
}
