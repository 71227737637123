//
// Main
//

// Object

.o-header {
    width: 100%;
    height: 100px;
    background-color: $verigray;
    z-index: 500;

    .logo {

        img {
            margin-top: 20px;
            float: left;
            width: 122px;
        }
    }

    .menu {
        margin-top: 30px;
        float: right;
        font-size: 34px !important;
        color: #fff;


        &:hover {
            color: $veribrand;
        }
    }

    .closebtn {
        margin-top: 28px;
        float: right;
        font-size: 34px !important;
        color: #fff;

    }

    .search {
        margin-top: 30px;
        margin-right: 20px;
        float: right;
        font-family: $font-family-sans-serif;
        font-size: 36px !important;
        color: #fff;

        @include media-breakpoint-up(md) {
            right: 100px;
        }

        &:hover {
            color: $veribrand;
        }
    }

    .overlay {
        position: fixed; /* Stay in place */
        height: 0%;
        width: 100%;
        z-index: 200; /* Sit on top */
        top: 0;
        left: 0;
        background-color: $veribrand;
        overflow: hidden; /* Disable scroll */
        overflow-y: scroll;
        transition: 0.2s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

        & a {
            text-decoration: none;
            color: #fff;
            display: block; /* Display block instead of inline */
            transition: 0.4s; /* Transition effects on hover (color) */

            &:hover,
            &:focus {
                color: #fff;
            }
        }
    }

    /* Position the content inside the overlay */
    .overlay-content {
        float: right;
        margin-top: 60px;
        text-align: right;
        clear: both;

        @include media-breakpoint-up(lg) {
            margin-top: 80px;
        }

        & a {
            font-family: $font-family-sans-serif;
            font-size: 2.4em;
            font-weight: 600;
            line-height: 1.2em;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
                font-size: 4em;
            }

            @include media-breakpoint-up(lg) {
                font-size: 5em;
            }

            i {
                display: none;
                float: right;
                margin: 6.5% 0 0 20px;
                font-size: .5em;
            }

            &:hover {

                color: $veribrand-darker;

                i {
                    display: block;
                }
            }
        }

    }
}