//
// Main
//

// Object

.c-callout {
    padding: 100px 0;
    color: #fff;
    background-color: $verigray-dark;
    text-align: center;
    overflow: hidden;


    h1 {
        margin-bottom: 20px;
        font-family: $font-family-sans-serif;
        font-size: 2em;
        font-weight: 700;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: 3em;
        }

        @include media-breakpoint-up(xl) {
            font-size: 4em;
        }

        & span {
            display: block;
            color: $veribrand;
        }
    }

    p {
        font-family: $font-family-serif;
        font-size: $font-size-base;
        font-weight: 300;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
        font-size: $font-size-lg;
        }

        @include media-breakpoint-up(md) {
            font-size: $font-size-lg + .1;
        }

        @include media-breakpoint-up(xl) {
            font-size: $font-size-lg + .2;
        }
    }
}
