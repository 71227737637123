//
// App
//

// Object

body, html {
    height: 100%;
    margin: 0;
    ::selection { background: $veribrand-light; }
    ::-webkit-selection {background: $veribrand-light; }
    ::-moz-selection { background: $veribrand-light; }
}

.o-app {
    margin: 0 auto;
    height: 100%;
}

.hide {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.valign {
	@include valign();
}
