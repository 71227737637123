//
// Services
//

// Object
.c-services {
    position: relative;
    padding: 60px 0 0;
    background-color: #fff;
    @include pager();

    @include media-breakpoint-up(lg) {
        padding: 120px 0 60px;
    }

    .service {
        margin-bottom: 40px;
        padding: 0;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            margin-bottom: 80px;
        }

        .icon {
            display: inline-block;
            height: 20%;
            width: 18%;
            margin-right: 5%;
            float: left;
            background-color: $veribrand;
            opacity: .7;
            border-radius: 20%;
               transition: all .3s ease-in-out;
               -moz-transition: all .3s ease-in-out;
               -webkit-transition: all .3s ease-in-out;
        }

        .copy {
            display: inline-block;
            width: 75%;
            float: left;

            @include media-breakpoint-up(md) {
                padding-right: 40px;
            }

            .title {
                display: inline-block;
                padding-bottom: 0;
                font-family: $font-family-sans-serif;
                font-size: $font-size-base;
                font-weight: 600;

                @include media-breakpoint-up(lg) {
                    font-size: $font-size-lg;
                }
            }

            .desc {
                font-family: $font-family-serif;
                font-weight: 300;

                @include media-breakpoint-up(lg) {
                    font-size: $font-size-base + .2;
                }
            }
        }

        &:hover {
            
            .icon {
                opacity: 1;
            }
        }
    }

    .hide {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    a.pager {
        color: $gray-light;

        &:hover {
            color: #fff;
        }
    }
}