//
// Embed
//

// Component
.c-embed {
    position: relative;
    display: block;
    height: 0;
    padding: 0 0 percentage(9 / 16) 0;
    overflow: hidden;

    &__item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

     &--16_by_9 {
        padding-bottom: percentage(9 / 16);
    }

    &--21_by_9 {
        padding-bottom: percentage(9 / 21);
    }

    &--4_by_3 {
        padding-bottom: percentage(3 / 4);
    }
}
