//
// Samples
//

// Object

.c-work-samples {
    padding: 60px 0;
    background-color: #dcdcdc;
    text-align: center;

    @include media-breakpoint-up(xl) {
        padding: 80px 0;
    }

    .no-padding {
        padding: 0;
    }

    img {
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }
    }

    .box {
        background-color: #fff;
    }

    .label {
        margin-bottom: 20px;
        font-family: $font-family-sans-serif;
        font-size: $font-size-sm;
        text-transform: uppercase;
        letter-spacing: .1em;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
        }
    }

    .label-text {
        text-align: center;
        color: $veribrand;
    }

    .shadow {
        @include shadow(2);
    }

    .embed-responsive {
        position: relative;

        .embed-vr {
            margin-bottom: 60px;
            width: 100%;
            height: 220px;
            border: 0;
            background-color: $gray-lightest;

            @include media-breakpoint-up(sm) {
                height: 320px;
            }

            @include media-breakpoint-up(md) {
                height: 400px;
            }

            @include media-breakpoint-up(lg) {
                height: 600px;
            }

            @include media-breakpoint-up(xxl) {
                margin-bottom: 100px;
                height: 800px;
            }
        }
    }

    .embed-video { 
        position: relative;
        margin-bottom: 40px;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            margin-bottom: 60px;
        }
    }

    .embed-video iframe,
    .embed-video object,
    .embed-video embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}